exports.components = {
  "component---src-components-oferta-template-jsx": () => import("./../../../src/components/OfertaTemplate.jsx" /* webpackChunkName: "component---src-components-oferta-template-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/Kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/Oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/Portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-realizacje-js": () => import("./../../../src/pages/Realizacje.js" /* webpackChunkName: "component---src-pages-realizacje-js" */),
  "component---src-pages-test-page-js": () => import("./../../../src/pages/TestPage.js" /* webpackChunkName: "component---src-pages-test-page-js" */),
  "component---src-pages-wspolpraca-js": () => import("./../../../src/pages/Wspolpraca.js" /* webpackChunkName: "component---src-pages-wspolpraca-js" */)
}

